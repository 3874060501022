/* .navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
 */
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.logo {
  position: relative;
  display: flex;
  height: 82px;
  width: 1360px;
}

/* .ant-layout {
  min-height: 100vh;
  display: flex;
  flex: auto;
  flex-direction:0
} */
